<template>
  <div class="item list wrap is-gapless is-narrow is-topless is-bottomless">
    <div class="col-img">
      <TrustYouRanked
        class="is-size-7 ribun"
        layout="vertical"
        v-bind="ranking && ranking.badgeData" />
      <PropertyGalleryHover
        :has-gallery-hidden="true"
        :gallery="property.gallery"
        :hero-image="property.heroImage"
        @click.native.prevent.stop="handleHeroImageClicked">
        <i
          v-if="$mq.mobile"
          slot="hero"
          class="gallery-hover--hero-slot el-icon-zoom-in is-size-4" />
      </PropertyGalleryHover>
    </div>
    <div class="col-content list-content">
      <div class="col-cotnent-wrapper">
        <h6 class="has-text-dark prop-name has-text-weight-semibold subtitle is-6 is-marginless">
          {{ property.name }}
        </h6>
        <router-link
          v-if="$route.name === 'searchMany'"
          class="is-italic property--location"
          :to="{
            name: 'searchMap', query: Object.assign({}, lv2State.queryParams), params: lv2State.queryParams, hash: '#pc' + property.propertyCode }"
          @click.native="lll">
          ~ {{ reviewsSummary && reviewsSummary.location && reviewsSummary.location.text }}
          {{ $t('components.PropertyMobile.view-map') }}
        </router-link>
        <p
          v-else
          class="is-italic has-text-grey property--location">
          ~ {{ reviewsSummary && reviewsSummary.location && reviewsSummary.location.text }}
        </p>
        <PpGap
          v-if="reviewsSummary && reviewsSummary.location"
          size="small" />
        <div class="list is-gapless wrap is-narrow is-topless">
          <StarRatings
            class="prop-star is-size-7 list-content"
            :ratings="property.starRating" />
          <TrustYouRating
            class="list-action"
            size="xs"
            v-bind="property.trustYou" />
        </div>
        <TrustYouPopularWith
          layout="text"
          class="is-size-7 has-text-dark"
          v-bind="reviewsSummary.popularWith" />
        <PpGap size="small" />
        <PropertyCovidSafetyInfo
          :covid-safety="property.covidSafety"
          class="is-size-7" />
        <PpGap
          v-if="property.covidSafety"
          size="small" />
        <template v-for="it in reviews.hotelTypeList">
          <TrustYouHotelType
            :key="it.categoryId"
            class="property--trustyou-hotel-type is-size-7 has-text-grey"
            text-type="shortText"
            v-bind="it" />
        </template>
      </div>
    </div>
    <div
      class="col-price list-content">
      <div class="col-content-wrapper list is-gapless is-narrow is-topless is-bottomless">
        <div class="list-content">
          <PackageRefundable :non-refundable="lowestPckg.nonRefundable" />
          <PackagePayAtHotel
            v-if="payAtHotel"
            :pay-at-hotel="payAtHotel" />
          <PackageIsPayLater :is-pay-later="lowestPckg.isPayLater" />
          <PackagesFoodCode
            class="is-size-7"
            :hide-room-only="true"
            :hide-description="true"
            :food-code="lowestPckg.foodCode" />
          <PpGap size="small" />
          <AdjustmentBadge
            class="is-size-7"
            :adjustment-pct="lowestPckg.initialAdjustmentPct" />
        </div>
        <div class="list-action is-size-6 has-text-black has-text-right">
          <div
            v-if="lowestPckg.initialAdjustmentPct < -0.01"
            class="has-text-line-through subtitle is-7 has-text-grey is-marginless">
            {{ displayRate | currencyObj({ factionDigits: 0 }) }}
          </div>
          <div class="is-5 is-marginless subtitle has-text-weight-semibold has-text-dark">
            {{ adjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}
          </div>
          <div class="is-size-7 has-text-grey">
            {{ nights|nightsAllRoom($store.state.inNightlyAvgRate,$store.state.searchMany.roomCount) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="marketRate"
      class="property--market-rate list-content">
      <a
        v-if="marketRate.supplier"
        :href="marketRate.deeplink_url"
        target="_blank"
        @click.stop.capture="() => {}">
        <div class="is-size-6">
          <p class="has-text-grey">
            <img
              v-show="marketRate && marketRate.supplier_logo_url"
              :src="marketRate && marketRate.supplier_logo_url"
              style="vertical-align:middle">
            <span
              v-show="marketRate && !marketRate.supplier_logo_url"
              class="has-text-weight-semibold has-text-dark">
              {{ marketRate && marketRate.supplier }}
            </span>
            <span style="vertical-align:middle;display:inline;">
              {{ marketRate && marketRate | currencyObj({ factionDigits: 0 }) }}
            </span>
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
// import PropertyAddress from '@/components/PropertyAddress'
import StarRatings from '@/components/StarRatings'
import AdjustmentBadge from '@/components/AdjustmentBadge'
import PropertyGalleryHover from '@/components/PropertyGalleryHover'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouRating from '@/components/TrustYou/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import PackagesFoodCode from './PackagesFoodCode'
import PackageRefundable from './PackageRefundable'
import PackagePayAtHotel from './PackagePayAtHotel'
import PackageIsPayLater from '@/components/PackageIsPayLater'
import PropertyCovidSafetyInfo from '@/components/PropertyCovidSafetyInfo'

export default {
  name: 'PropertyMobile',
  components: {
    PackageIsPayLater,
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PropertyGalleryHover.name]: PropertyGalleryHover,
    // [PropertyAddress.name]: PropertyAddress,
    [PackagesFoodCode.name]: PackagesFoodCode,
    [BadgeOutline.name]: BadgeOutline,
    [StarRatings.name]: StarRatings,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouBadgeList.name]: TrustYouBadgeList,
    PackageRefundable,
    PackagePayAtHotel,
    PropertyCovidSafetyInfo
  },
  props: {
    property: Object,
    packages: Array,
    nights: Number,
    layout: String
  },
  data () {
    return {
      // move to vuex store
      // inNightlyAvgRate: true
    }
  },
  computed: {
    mapLink () {
      return false
      // return this.layout !== 'simple'
    },
    ranking () {
      if (this.reviews.badgeList && this.reviews.badgeList.length) {
        return this.reviews.badgeList.find(({ badgeType }) => badgeType === 'ranking')
      }
      return {}
    },
    reviews () {
      if (this.property.reviews && this.property.reviews.summary) {
        return this.property.reviews
      }
      return {
        summary: {},
        badgeList: [],
        hotelTypeList: []
      }
    },
    lowestPckg () {
      return this.packages[0] || {}
    },
    marketRates () {
      return (this.$store.state.inNightlyAvgRate ? this.lowestPckg.unitMarketRates : this.lowestPckg.marketRates) || []
    },
    displayRate () {
      return this.$store.state.inNightlyAvgRate ? this.lowestPckg.unitDisplayRate : this.lowestPckg.displayRate
    },
    adjustedDisplayRate () {
      return this.$store.state.inNightlyAvgRate ? this.lowestPckg.unitAdjustedDisplayRate : this.lowestPckg.adjustedDisplayRate
    },
    marketRate () {
      return this.marketRates[0] || {}
    },
    payAtHotel () {
      return this.packages[0].payAtHotel
    },
    reviewsSummary () {
      try {
        return this.reviews.summary || {}
      } catch {
        return {}
      }
    }
  },
  methods: {
    handleHeroImageClicked () {
      this.$emit('heroImageClicked', { property: this.property, packages: this.packages })
    },
    lll (evt) {
      console.log(evt)
      evt.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .item {
    line-height: 1rem;
    // padding: ($bleed * 2) $bleed;
    margin: 0;
    // justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .property--location {
    font-size: 0.75em;
  }
  .property--market-rate {
    border-top: 1px solid $list-border-color;
    padding: $bleed $bleed * 2;
    text-align: center;
    background-color: $white-bis;
    border-radius: 0 0 $bleed $bleed;
  }
  .col-img {
    padding: 0;
    // height: 100%;
    align-self: stretch;
  }
  .col-content {
    padding: $bleed * 2;
    .col-cotnent-wrapper {
      height: 100%;
      flex-direction: column;
    }
  }
  .col-pckg-details {
    display: flex;
    align-items: flex-end;
  }
  .col-price {
    padding: $bleed $bleed * 2 $bleed * 2 $bleed * 2;
    flex: none;
    width: 100%;
    .col-content-wrapper {
      align-items: flex-end;
    }
    // width: 100%;
    // padding-top: 0;
    // min-width: 200px;
    // align-self: center;
    // .col-cotnent-wrapper {
    //   flex-direction: row;
    // }
    // background-color: #fff;
    // padding: $bleed / 2 $bleed !important;
    // align-self: flex-end;
    // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
  }
  .ribun {
    position: absolute;
    z-index: 1;
    margin: $bleed $bleed $bleed -#{$bleed * 2};
  }
  .prop-star {
    width: 70px;
  }
  .item-gap {
    margin-top: $bleed * 25;
  }
  // .fixed-height {
  //   height: calc(100vh - #{$zu-header-height} - 112px - 50px);
  //   overflow-y: scroll;
  // }
  // #map-canvas {
  //   height: calc(100vh - #{$zu-header-height} - 112px);
  //   width: 100%;
  // }
</style>
